import React from 'react';
import { css } from '@emotion/core';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { useRecoilValue } from 'recoil';
import { network } from '../../../../shared-state/main';
import { Text } from 'rebass';
import { useTranslation } from 'react-i18next';

const toggleScroll = (show: boolean): void => {
  if (typeof window === 'undefined') return;
  const element = document.querySelector('html');
  if (!element) return;
  if (show) {
    element.style.overflow = 'hidden';
  } else {
    element.style.overflow = 'auto';
  }
};

export const Spinner: React.FC = () => {
  const { t } = useTranslation();
  const _network = useRecoilValue(network);

  const textCss = css`
    color: white;
    margin-top: 20px;
  `;

  const override = css`
    margin-bottom: 20px;
  `;

  const showSpinner = _network.requests > 0;

  toggleScroll(showSpinner);

  if (showSpinner) {
    return (
      <div className="sweet-loading">
        <PropagateLoader color={'#f47e20'} css={override} loading={true} />

        <Text css={textCss}>{t('webshop_PleaseWait')}</Text>
      </div>
    );
  }

  return null;
};
