import { css } from '@emotion/core';

export const containerMobile = css`
  width: 95%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 370px) {
    .vector-logo {
      width: 150px !important;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row-reverse;
  }
`;

export const container = css`
  width: 95%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
`;

export const containerWithPadding = css`
  padding: 0px 250px;
  height: 128px;

  @media screen and (min-width: 900px) and (max-width: 1100px) {
    .vector-logo {
      height: 35px;
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    padding: 0px 50px;
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    padding: 0px 50px;
    height: 94px;
  }

  @media screen and (min-width: 1400px) {
    padding: 0px 100px;
    height: 94px;
  }

  @media screen and (min-width: 1500px) {
    padding: 0px 150px;
  }

  @media screen and (min-width: 1601px) {
    padding: 0px 200px;
  }

  @media screen and (min-width: 2000px) {
    padding: 0px 200px;
  }

  @media screen and (max-width: 450px) {
    padding: 0px 20px;
  }

  @media screen and (min-width: 2200px) {
    .vector-logo {
      height: 60px;
    }

    height: 128px;
  }
`;

export const webShopContainer = css`
  background-color: white;
  width: 95%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
`;
