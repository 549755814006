import React from 'react';
import { Box } from 'rebass';
import { Modal } from '../../layout/Modal/Modal.component';
import { css } from '@emotion/core';
import { MdClose } from 'react-icons/md';

const modalStyles = (backgroundImg?: string) => css`
  background-color: ${backgroundImg} ? none : white;
  border-radius: 6px;
  padding: 40px;
`;

const mobileModalStyles = css`
  @media (max-width: 900px) {
    padding: 10px;
  }
`;

const modalHeaderStyles = css`
  padding: 20px;
  @media (max-width: 650px) {
    padding: 20px;
  }
`;

const closeModal = css`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: white;
  font-size: 32px;
  z-index: 34;
`;

const modalMobileStyles = css`
  @media (max-width: 900px) {
    width: 100% !important;
    transform: scale(0.9);
  }

  @media (max-height: 900px) {
    transform: scale(0.7);
  }
`;

interface MarketingPopupProps {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  width?: number;
  style?: any;
  gradient?: boolean;
  gradientColor?: any;
  modalBackgroundColor?: string;
  modalBackgroundImage?: any;
  modalHeader?: boolean;
  modalHeaderContent?: any;
  modalHeaderStyle?: any;
  modalHeaderBackgroundColor?: string;
  modalBody?: boolean;
  modalBodyContent?: any;
  modalBodyBackgroundColor?: string;
  containerClass?: string;
}

const MarketingPopup = (props: MarketingPopupProps) => {
  return (
    <div className={props.containerClass}>
      <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
        <Box
          style={{
            width: `${props.width}px`,
            backgroundColor: props.modalBackgroundColor,
            backgroundImage: `url(${props.modalBackgroundImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            overflow: 'hidden',
            borderRadius: '20px',
          }}
          css={modalMobileStyles}
        >
          <Box
            css={[modalStyles(props.modalBackgroundImage), mobileModalStyles]}
            style={{
              ...props.style,
              backgroundImage: props.gradient ? 'linear-gradient(' + props.gradientColor + ')' : null,
            }}
          >
            <MdClose css={closeModal} onClick={() => props.setShowModal(false)} />
            {props.modalHeader ? (
              <Box>
                {props.modalHeaderContent && (
                  <Box
                    css={modalHeaderStyles}
                    style={{
                      ...props.modalHeaderStyle,
                      backgroundColor: props.modalHeaderBackgroundColor,
                    }}
                  >
                    {props.modalHeaderContent}
                  </Box>
                )}
              </Box>
            ) : null}
            <MdClose
              css={closeModal}
              onClick={() => {
                props.setShowModal(false);
              }}
            />

            {props.modalBody ? (
              <Box>
                {props.modalBodyContent && (
                  <Box
                    css={modalHeaderStyles}
                    style={{
                      ...props.modalHeaderStyle,
                      backgroundColor: props.modalBodyBackgroundColor,
                    }}
                  >
                    {props.modalBodyContent}
                  </Box>
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default MarketingPopup;
