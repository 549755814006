import React from 'react';
import Helmet from 'react-helmet';
import { metaData } from './metaData';
import { theme } from '../layouts/main.layout';

export interface MetaObjectI {
  title?: string;
  description?: string;
  keywords?: string;
  lang?: string;
  noIndex?: boolean;
}

export interface OgObjectI {
  title?: string;
  description?: string;
  image?: string;
}

export interface HeadPropsI {
  footerTopSection?: boolean;
  meta?: MetaObjectI;
  og?: OgObjectI;
  lang?: string;
  fullWidth?: boolean;
}

const META = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  KEYWORDS: 'keywords',
  LANG: 'lang',
};

const OG = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  IMAGE: 'image',
};

const Head: React.FC<HeadPropsI> = (props) => {
  const getMetaValue = (type: string): string | undefined => {
    let metaValue;

    switch (type) {
      case META.LANG:
        {
          metaValue = props.meta?.lang ?? metaData.language;
        }
        break;

      case META.TITLE:
        {
          metaValue = props.meta?.title ?? metaData.title;
          metaValue += ' | VOZZi';
        }
        break;

      case META.DESCRIPTION:
        {
          metaValue = props.meta?.description ?? metaData.description;
        }
        break;

      case META.KEYWORDS:
        {
          metaValue = props.meta?.keywords ?? metaData.keywords;
        }
        break;

      case OG.TITLE:
        {
          metaValue = props.meta?.title ?? metaData.title;
          metaValue += ' | VOZZi';
        }
        break;

      case OG.DESCRIPTION:
        {
          metaValue = props.og?.description ?? props.meta?.description ?? metaData.description;
        }
        break;

      case OG.IMAGE:
        {
          metaValue = props.og?.image ?? metaData.image;
        }
        break;
    }

    return metaValue;
  };

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />

      <meta name="theme-color" content={theme.colors.white} media="(prefers-color-scheme: light)" />
      <meta name="theme-color" content={theme.colors.darkGray} media="(prefers-color-scheme: dark)" />

      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />

      {props.meta?.noIndex ? <meta name="robots" content="noindex" /> : ''}

      <link rel="alternate" hrefLang="sr-RS" href="https://rs.vozzi.app/sr" />
      <link rel="alternate" hrefLang="en-RS" href="https://rs.vozzi.app/en" />

      <link rel="alternate" hrefLang="sr-ME" href="https://me.vozzi.app/me" />
      <link rel="alternate" hrefLang="en-ME" href="https://me.vozzi.app/en" />

      <link rel="alternate" hrefLang="mk-MK" href="https://mk.vozzi.app" />

      <link rel="alternate" hrefLang="bs-BA" href="https://ba.vozzi.app" />

      <link rel="alternate" hrefLang="sl-SI" href="https://si.vozzi.app" />

      <link rel="alternate" hrefLang="x-default" href="https://rs.vozzi.app/sr" />

      <title>{getMetaValue(META.TITLE)}</title>
      <meta name="apple-itunes-app" content="app-id=1119014988" />
      <link rel="manifest" href="../../manifest.json" />
      <meta name="description" content={getMetaValue(META.DESCRIPTION)} />
      <meta name="keywords" content={getMetaValue(META.KEYWORDS)} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@VOZZi App" />
      <meta name="twitter:creator" content="@VOZZi App" />
      <meta name="twitter:title" content={getMetaValue(OG.TITLE)} />
      <meta name="twitter:description" content={getMetaValue(OG.DESCRIPTION)} />
      <meta name="twitter:image" content={getMetaValue(OG.IMAGE)} />
      <meta name="facebook-domain-verification" content="kdqjbn0bwkg2wxfst8ynfnn15qzv1t" />

      <meta property="og:title" content={getMetaValue(OG.TITLE)} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={getMetaValue(OG.IMAGE)} />
      <meta property="og:site_name" content="VOZZi App" />
      <meta property="og:description" content={getMetaValue(OG.DESCRIPTION)} />

      <html lang={props.lang} prefix="og: http://ogp.me/ns#" />
    </Helmet>
  );
};

export { Head };
