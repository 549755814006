import { atom, selector } from 'recoil';
import {
  AllSecureDataI,
  BundlePaySlipI,
  NavbarI,
  NetworkI,
  PayslipI,
  PurchasePackageI,
  SuccessfullPurchaseI,
  UserDataI,
  UserLocationStateI,
  WebShopI,
  WebshopUserI,
  WholeWebshopStateI,
} from './main.type';
import '../configs/axios';
// import { packages as packagesData } from '../configs/packages';

export const packages: any = {
  EU1: 'eu1',
  EU2: 'eu2',
  EU3: 'eu3',
  VOZZI40: 'vozzi40',
  VOZZIRS: 'vozziRS',
};

export const bundlePaySlip = atom<BundlePaySlipI>({
  key: 'bundlePaySlip',
  default: {
    bundleId: null,
    bundleName: null,
    bundlePrice: null,
  },
});

export const webshopUser = atom<WebshopUserI>({
  key: 'webshopUser',
  default: {
    userFirstName: null,
    userLastName: null,
    userMobileNumber: null,
    userEmail: null,
    lat: null,
    lng: null,
    bundleId: null,
    realBundleId: null,
    resourcePath: null,
  },
});

export const userData = atom<UserDataI>({
  key: 'userData',
  default: {
    firstName: null,
    lastName: null,
    mobileNumber: null,
    email: null,
    address: null,
    city: null,
    postalCode: null,
  },
});

export const successfullPurchase = atom<SuccessfullPurchaseI>({
  key: 'successfullPurchase',
  default: {
    email: null,
    mobileNumber: null,
  },
});

export const purchasePackage = atom<PurchasePackageI>({
  key: 'purchasePackage',
  default: {
    bundle: null,
    packageName: null,
    packagePrice: null,
    currency: null,
    bundleKeyName: null,
  },
});

export const allSecureData = atom<AllSecureDataI>({
  key: 'allSecureData',
  default: {
    vozziApiCallbackUrl: null,
  },
});

export const webShop = atom<WebShopI>({
  key: 'webShop',
  default: {
    packageDetails: null,
    userExists: null,
    userChecked: false,
    paymentType: null,
  },
});

export const userLocation = atom<UserLocationStateI>({
  key: 'userLocation',
  default: {
    updatedAt: null,
    lat: null,
    lng: null,
  },
});

export const network = atom<NetworkI>({
  key: 'requests',
  default: {
    requests: 0,
  },
});

export const navbar = atom<NavbarI>({
  key: 'navbar',
  default: {
    isOpen: false,
  },
});

export const payslip = atom<PayslipI>({
  key: 'payslip',
  default: {
    price: 0,
    clientBundleId: null,
  },
});

export const wholeWebshopState = selector<WholeWebshopStateI>({
  key: 'wholeWebshopState',
  get: ({ get }) => {
    const userData_ = get(userData);
    const webShop_ = get(webShop);
    const userLocation_ = get(userLocation);
    return {
      userData: userData_,
      webShop: webShop_,
      userLocation: userLocation_,
    };
  },
});

// TO-DO: define types instead of any
// OLD WEB SHOP, NOW WE GET BUNDLES FROM SERVER2.0
// export const packagesState = atom<any>({
//   key: 'packagesState',
//   default: packagesData[COUNTRY],
// });

// export const pricesData = selector<any>({
//   key: 'pricesData',
//   get: async () => {
//     const [response] = await to(axios.get('/bundles-for-web'));

//     if (response) {
//       return response.data;
//     }

//     return null;
//   },
// });

// export const packagesWithPrices = selector<any>({
//   key: 'packagesWithPrices',
//   get: ({ get }) => {
//     const packages = get(packagesState);
//     const prices = get(pricesData);

//     if (packages && prices) {
//       const mapPackages = packages.map((packageLangGroup: any) => {
//         const mapPackageLangGroup = packageLangGroup.list.map((pck: Package) => {
//           const packagePrice = prices.find((prc: any) => prc.keyName === pck.packageKeyName);

//           return { ...pck, ...packagePrice };
//         });

//         return { ...packageLangGroup, list: [...mapPackageLangGroup] };
//       });

//       return mapPackages;
//     }

//     return packages;
//   },
// });
