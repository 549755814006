import React from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { css } from '@emotion/core';

interface ModalProps {
  show: boolean;
  onHide: () => void;
  children: any;
}

const modalContainerCss = (show: boolean) => css`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
  overflow: auto;
  width: 100%;
  height: 100%;
  display: ${!show && 'none'};
`;

const modalBodyCss = css`
  position: relative;
  margin: auto;
  z-index: 9999999;

  @media screen and (min-width: 832px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  @media screen and (max-width: 768px) {
    padding-top: 0px;
  }
`;

const modalBackdropCss = css`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  z-index: 5;
  width: 100%;
`;

export const Modal = ({ show, onHide, children }: ModalProps) => {
  return (
    <RemoveScroll enabled={show ? true : false}>
      <div css={modalContainerCss(show)}>
        {show && (
          <div>
            <div css={modalBodyCss}>{children}</div>
            <div css={modalBackdropCss} onClick={onHide} />
          </div>
        )}
      </div>
    </RemoveScroll>
  );
};
