import React, { useEffect, useState } from 'react';
import { Box, Image } from 'rebass';
import { ThemeProvider } from 'emotion-theming';
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';
import { css, Global } from '@emotion/core';
import emotionNormalize from 'emotion-normalize';
import CookieConsent from 'react-cookie-consent';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import Lottie from 'react-lottie';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import loadingAnimation from '../../static/lottie/loading.json';
import '../components/functional/new_components/Loader/loader.css';

import { COUNTRY } from '../configs/env';
import { Head, HeadPropsI } from '../meta/Head.component';
import { container } from '../shared-styles/container.css';
import { Spinner } from '../pages_/wbshop/components/Spinner/Spinner.component';
import MarketingPopup from '../components/functional/ModalPopup/MarketingPopup';
import TrialPackagePopup from '../components/functional/ModalPopup/components/trial-package';

export let fontFamily = '"Manrope" ,sans-serif';
if (COUNTRY === 'mk') {
  fontFamily = '"Fira Sans" ,sans-serif';
}

export type breakpointKeysT = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const breakpoints: any = [
  '20em', // 320px
  '32.5em', // 520px
  '40em', // 640px
  '52em', // 832px
  '64em', // 1024px
  '80em', // 1280px
  '120em', // 1920px
];

breakpoints.xxs = breakpoints[0]; // 320px
breakpoints.xs = breakpoints[1]; // 520px
breakpoints.sm = breakpoints[2]; // 640px
breakpoints.md = breakpoints[3]; // 832px
breakpoints.lg = breakpoints[4]; // 1024px
breakpoints.xl = breakpoints[5]; // 1280px
breakpoints.xxl = breakpoints[6]; // 1920px

// @TODO - try patching the styled-system props so it can recieve the type generic for breakpointsT
export type breakpointsT = typeof breakpoints;

export const theme = {
  colors: {
    white: '#ffffff',
    black: '#1E1E23',
    primaryTextGray: '#3b3b3c',
    borderGray: '#aeaeae',
    borderDarkGray: '#3b3a3b',
    lightGray: '#aeaeae',
    mediumGray: '#767576',
    darkGray: '#3b3a3b',
    backgroundLightGray: '#ebebeb',
    gray: '#d8d8d8',
    vozziVividOrange: '#fe7f12',
    vozziOrange: '#f47e20',
    vozziLightOrange: '#ff7f11',
    backgroundGray: '#f9f9f9',
    mainGradientLeft: '#14dee1',
    mainGradientRight: '#130cb7',
    warningRed: '#d0021b',
    boxShadow: '1px 10px 15px -5px rgba(0, 0, 0, 0.1)',
    gradient: 'linear-gradient(to right, #14DEE1 0%, #148DD1 35%, #130CB7 100%)',
    whiteGradient: 'linear-gradient(to top, #fff 0%, rgba(255, 255, 255, 0) 100%)',
    overlayGradient: 'linear-gradient(to top, #000000 0%, rgba(255, 255, 255, 0) 100%)',
    infoOrange: '#fdf2e8',
    infoBlue: '#e7eef9',
    blue: '#1459c6',
    discountRed: '#cd0010',
  },
  space: [
    // margin and padding
    0, 4, 8, 16, 32, 64, 94, 128, 256,
  ],
  breakpoints: breakpoints as breakpointsT, // Uses (min-width)
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  fonts: {
    body: fontFamily,
    landingTitle: fontFamily,
    heading: fontFamily,
    text: fontFamily,
    monospace: 'monospace',
    sansSerif: 'sans-serif',
  },
  fontSizes: [12, 14, 16, 22, 24, 26, 30, 32, 48, 54, 64, 96, 128],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 600,
    extraBold: 800,
    extraLight: 200,
  },
  buttons: {
    primary: {
      color: 'white',
      // bg: 'primary',
      backgroundColor: '#fe7f12',
      borderRadius: '6px',
    },
    outline: {
      color: 'vozziVividOrange',
      bg: 'transparent',
      boxShadow: 'inset 0 0 0 2px',
    },
  },
};

export type Theme = typeof theme;

const Root = styled.div`
  line-height: 1.5;
`;

export const Container = styled.div`
  ${container}
`;

export const globalStyles = css`
  ${emotionNormalize}
  html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    max-width: 100vw;
    font-display: swap;
  }

  #vertical-slider-area {
    overflow-y: scroll;
  }

  #vertical-slider-area .slick-slider::-webkit-scrollbar {
    display: none !important;
  }

  .scroll-slider::-webkit-scrollbar {
    display: none !important;
  }

  .scroll-slider {
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }

  #vertical-slider-area .slick-slider {
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }

  #vertical-slider-area::-webkit-scrollbar {
    display: none;
  }

  .custom-wrapper {
    overflow: hidden !important;
  }

  #vertical-slider-area {
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-size: ${theme.fontSizes[2]}px;
    overflow-x: hidden;
    width: 100%;
    font-family: ${theme.fonts.body};
    color: ${theme.colors.primaryTextGray};
    max-width: 100vw;
    left: 0;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.vozziVividOrange};
  }

  a,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  strong,
  b,
  i,
  u {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin: 0;
  }

  ul,
  ol,
  li {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  main {
    max-width: 100vw !important;
    left: 0;
  }

  @media screen and (max-width: 768px) {
    html {
      max-width: 100vw;
      overflow-x: hidden;
    }
  }

  @media screen and (min-width: 768px) {
    span.break-on-md-and-lg {
      clear: both;
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    span.break-on-md-and-lg {
      display: block;
    }
  }

  /* This is css for override Google Map InfoWindow close button X */

  .gm-ui-hover-effect {
    background-color: #dbdbdb !important;
    margin-right: 5px !important;
    border-radius: 25% !important;
  }

  .sweet-loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 4;
    position: fixed;
  }

  .redSpan {
    background-color: #f47e20;
    display: inline-block;
    padding: 8px;
  }

  .orangeSpan {
    background-color: #e82d00;
    display: inline-block;
    padding: 8px;
  }

  @media (max-width: 800px) {
    .react-switch-bg {
      height: 22px !important;
      width: 50px !important;
    }

    .react-switch-handle {
      height: 20px !important;
      width: 20px !important;
    }

    .orangeSpan {
      padding: 3px 8px;
    }

    .redSpan {
      padding: 3px 8px;
    }
  }

  .black-friday-container {
    z-index: 99999 !important;
  }

  .black-friday-container .swal2-popup {
    top: 5%;
  }

  .btn-black-friday {
    width: 280px;
    margin: 0 auto;
    border: none !important;
    outline: none;
    font-size: 16px !important;
    font-weight: normal !important;
  }

  .black-friday-container .swal2-actions {
    margin-top: 10px;
  }

  .black-friday-container .swal2-validation-message {
    font-size: 12px;
    background-color: transparent;
    color: white;
  }

  .black-friday-container .swal2-footer {
    border-top: 0;
    padding-bottom: 40px;
    padding-top: 0;
    margin-top: 5px;
  }

  .custom-input {
    height: 40px !important;
    width: 280px !important;
    outline: none !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 15px !important;
    font-size: 12px !important;
    margin: 0 auto !important;
    margin-top: 30px !important;
    background-color: white !important;
    box-shadow: none !important;
  }

  .black-friday-container .custom-input:focus {
    box-shadow: none !important;
  }

  @media (max-width: 700px) {
    #counter-container {
      margin: 20px auto !important;
    }

    #counter-container img {
      width: inherit;
    }

    .black-friday-container .text-container {
      font-size: 0.9rem !important;
    }

    .black-friday-container .black-friday-title {
      font-size: 1.5rem !important;
    }

    .logo {
      height: 30px !important;
      margin: 20px auto !important;
    }

    .black-friday-gift {
      margin: 20px auto !important;
      margin-top: 0 !important;
    }

    .black-friday-container .custom-input {
      margin-top: 20px !important;
    }
  }
`;

export interface MainLayoutPropsI extends HeadPropsI {
  backgroundColor?: string;
  fullWidth?: boolean;
  // ....
}

const MainLayout: React.FC<MainLayoutPropsI> = (props) => {
  const { t, i18n } = useTranslation();
  const [showMarketingPopup, setShowMarketingPopup] = useState(false);
  const [showAnnouncementPopup, setShowAnnouncementPopup] = useState(false);
  const { backgroundColor } = props;
  const lang = i18n.language;

  const loaderAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    const loaderWrapper = document.querySelector('.loader-wrapper');
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const hideLoaderWrapper = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      loaderWrapper.classList.add('loader-wrapper--hide');
    };

    if (COUNTRY === 'rs' || COUNTRY === 'me') {
      if (!window.sessionStorage.getItem('announcementPopup')) {
        setTimeout(() => {
          setShowAnnouncementPopup(true);
          window.sessionStorage.setItem('announcementPopup', String(true));
        }, 1000);
      }
    }

    if (COUNTRY === 'bg') {
      if (
        !window.location.pathname.includes('/bg/uslovija-za-polzvane') &&
        !window.location.pathname.includes('/bg/levski') &&
        !window.location.pathname.includes('/mk/nikana-travel')
      ) {
        if (!window.sessionStorage.getItem('trialPackagePopup')) {
          setTimeout(() => {
            setShowMarketingPopup(true);
            window.sessionStorage.setItem('trialPackagePopup', String(true));
          }, 5000);
        }
      }
    }

    setTimeout(() => {
      hideLoaderWrapper();
    }, 1000);
  }, []);

  useEffect(() => {
    const loaderWrapper = document.querySelector('.loader-wrapper');
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const hideLoaderWrapper = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      loaderWrapper.classList.add('loader-wrapper--hide');
    };

    setTimeout(() => {
      hideLoaderWrapper();
    }, 1000);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="loader-wrapper">
        <div className="loader">
          <Lottie options={loaderAnimationOptions} height={400} width={400} />
        </div>
      </div>
      <Spinner />
      <Head lang={lang} meta={props.meta ?? undefined} og={props.og ?? undefined} />
      <Box backgroundColor={backgroundColor ? backgroundColor : 'backgroundGray'}>
        <Root>
          <div>
            {!props.fullWidth ? (
              <Container>
                <Global styles={globalStyles} />

                {props.children}
              </Container>
            ) : (
              <div>
                <Global styles={globalStyles} />

                {props.children}
              </div>
            )}
          </div>
        </Root>
      </Box>
      <CookieConsent
        // enableDeclineButton
        location="bottom"
        buttonText={t('btn_Agree')}
        buttonStyle={{
          background: theme.colors.vozziOrange,
          color: theme.colors.white,
          borderRadius: '4px',
          padding: '10px 25px',
        }}
        // declineButtonText="Ne slažem se"
        cookieName="gatsby-gdpr-google-analytics"
      >
        {t('cookie')}
      </CookieConsent>

      {/* <FairCampaignPopup showModal={showFairPopup} setShowModal={setShowFairPopup} /> */}

      <MarketingPopup
        showModal={showMarketingPopup}
        setShowModal={setShowMarketingPopup}
        width={800}
        gradient={false}
        style={{ padding: 0 }}
        gradientColor={'rgba(1,1,1,0), black'}
        modalBackgroundImage={null}
        modalBackgroundColor="white"
        modalHeader={true}
        modalHeaderContent={COUNTRY === 'bg' ? <Image src="/img/trial-package-bg.png" /> : <Image src="/img/trial-package.png" />}
        modalHeaderStyle={{ textAlign: 'center', padding: 0 }}
        modalBody={true}
        modalBodyContent={
          <>
            <TrialPackagePopup />
          </>
        }
        containerClass={'trial-package-container'}
      />

      <MarketingPopup
        showModal={showAnnouncementPopup}
        setShowModal={setShowAnnouncementPopup}
        width={800}
        gradient={false}
        style={{ padding: 0 }}
        gradientColor={'rgba(1,1,1,0), black'}
        modalBackgroundImage={null}
        modalBackgroundColor="white"
        modalHeader={true}
        modalHeaderContent={<Image src="/img/vozzi-home.jpg" />}
        modalHeaderStyle={{ textAlign: 'center', padding: 0 }}
        modalBody={true}
        modalBodyContent={
          <>
            <Box style={{ padding: '2em' }}>
              <h3
                style={{
                  fontSize: '1.1em',
                  color: '#333',
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 300,
                }}
              >
                Poštovani korisnici i saradnici, <br />
                <br />
                Ovim putem vas obaveštavamo da VOZZi aplikacija, prodaja paketa i pružanje usluga pomoći na putu prestaje sa radom i biva
                suspendovana. <br />
                <br />
                Nakon vrlo mučne borbe za opstanak na domaćem tržištu, tim Kompanije zajedno sa njenim osnivačem, morao je da donese ovu, za
                nas vrlo bolnu odluku. Na žalost, mi nismo više u stanju da se borimo protiv zloupotreba koje su prisutne u ovom poslu i sa
                činjenicom da naš region jednostavno nije spreman za digitalizaciju i transparentno poslovanje. <br />
                <br />
                Nadamo se da nam svima dolaze neka bolja i srećnija vremena. <br />
                <br />
                Za dalje informacije o daljem toku razvoja, molimo vas da nas kontaktirate putem mejla{' '}
                <a href="mailto:support@vozzi.app" target="_blank" rel="noreferrer">
                  support@vozzi.app
                </a>
              </h3>
            </Box>
          </>
        }
      />
    </ThemeProvider>
  );
};

export { MainLayout };
