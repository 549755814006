import React from 'react';
import { COUNTRY } from '../../../../configs/env';
import { Box, Image } from 'rebass';
import { useTranslation } from 'react-i18next';
import './trial-package.css';

const TrialPackagePopup = (props: any) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box>
        <h1
          style={{
            fontSize: COUNTRY === 'bg' ? '30px' : 'auto',
            color: '#333',
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 800,
          }}
        >
          {t('trial_package_popup_title_part_one')}
          <br />
          {t('trial_package_popup_title_part_two')}
        </h1>
      </Box>

      <>
        <div
          className="mobile-block"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '30px 50px',
            maxWidth: COUNTRY === 'bg' ? '600px' : 'auto',
            margin: COUNTRY === 'bg' ? '0 auto' : 'auto',
          }}
        >
          <div className={`mobile-flex ${COUNTRY === 'bg' ? 'mobile-flex-bg' : null}`}>
            <Image
              className="desktop-view qr-icon"
              style={{ height: COUNTRY === 'bg' ? '115px' : '100px' }}
              src={`/img/trial-package-popup/trial-package-qr-${COUNTRY}.png`}
            />
            <Image
              className="mobile-view download-icon download-icon-bg"
              style={{
                height: COUNTRY === 'bg' ? '115px' : '100px',
                display: 'none',
                width: COUNTRY === 'bg' ? 'auto' : 'auto',
                marginLeft: COUNTRY === 'bg' ? '-11px' : '-5px',
              }}
              src={'/img/trial-package-popup/trial-package-download.png'}
            />
            {COUNTRY === 'bg' ? (
              <div
                className="margin-mobile-bg"
                dangerouslySetInnerHTML={{ __html: t('trial_package_popup_first_icon_text') }}
                // style={{ height: '30px', fontSize: '16px', fontFamily: 'Tahoma', marginTop: '10px' }}
              ></div>
            ) : (
              <p
                style={{
                  minHeight: COUNTRY === 'bg' ? '60px' : 'auto',
                  height: COUNTRY === 'bg' ? '60px' : '30px',
                  fontSize: '16px',
                  fontFamily: 'Tahoma',
                  marginTop: '10px',
                }}
              >
                {t('trial_package_popup_first_icon_text')}
              </p>
            )}
          </div>
          <div className={`mobile-flex ${COUNTRY === 'bg' ? 'mobile-flex-bg' : null} mobile-flex-bg-no-bottom-margin`}>
            <Image
              className={`mobile-margin-register ${COUNTRY === 'bg' ? 'mobile-margin-bg' : null}`}
              style={{ height: '100px', paddingLeft: COUNTRY === 'bg' ? '0 !important' : 'auto' }}
              src={'/img/trial-package-popup/trial-package-register.png'}
            />
            <p
              style={{
                minHeight: COUNTRY === 'bg' ? '60px' : 'auto',
                height: COUNTRY === 'bg' ? '60px' : '30px',
                fontSize: '16px',
                fontFamily: 'Tahoma',
                marginTop: '10px',
              }}
            >
              {t('trial_package_popup_second_icon_text')}
            </p>
          </div>
          <div className={`mobile-flex ${COUNTRY === 'bg' ? 'mobile-flex-bg' : null}`}>
            <Image
              className={`mobile-margin-activate ${COUNTRY === 'bg' ? 'mobile-margin-bg' : null}`}
              style={{ height: '100px', width: COUNTRY === 'bg' ? 'auto' : 'auto' }}
              src={'/img/trial-package-popup/trial-package-activate.png'}
            />
            <p
              className="margin-left-mobile mobile-padding-15"
              style={{
                minHeight: COUNTRY === 'bg' ? '60px' : 'auto',
                height: COUNTRY === 'bg' ? '60px' : '30px',
                fontSize: '16px',
                fontFamily: 'Tahoma',
                marginTop: '10px',
              }}
            >
              {t('trial_package_popup_third_icon_first_part_text')} <br className="desktop-view" />
              {t('trial_package_popup_third_icon_second_part_text')}
            </p>
          </div>

          <a href={t('trial_package_popup_mobile_button_link')} style={{ display: 'none' }} className="trial-package-mobile-button">
            {t('trial_package_popup_mobile_button_label')}
          </a>
        </div>
        {COUNTRY === 'bg' ? (
          <p className="disclaimer-text" style={{ fontStyle: 'italic', fontSize: 12, marginBottom: 30 }}>
            {t('trial_package_popup_dislaimer_text')}
          </p>
        ) : null}
      </>
    </>
  );
};

export default TrialPackagePopup;
